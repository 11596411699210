import React, { useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Main = () => {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  const handleClickScroll = useCallback((id) => {
    if (id === 'top') {
      window.scrollTo(0, 0)
    }
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  return (
  <>
    <footer className="footer-bg footer-p" id="contact">
      <div className="footer-top  pt-90 pb-40" style={{ backgroundColor: "#16150b" }}>
        <div className="container">
          <div className="row justify-content-between">                          
            <div className="col-xl-4 col-lg-4 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title mb-30">
                <Link to="/">
                  <img src="/assets/pesto-logo_200.svg" alt="logo" />
                </Link>
                </div>
                <div className="f-contact">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="icon fal fa-phone"></i>
                      <a href="tel:+36709469240">+36 70 946-9240</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="icon fal fa-envelope"></i>
                      <a href="mailto:pestohostel@gmail.com">
                      pestohostel@gmail.com
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="icon fal fa-map-marker-check"></i>
                      <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/6eR1VQK6ZoRXorPS8">1053 Budapest, Veres Pálné utca 16.</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title ">
                  <h2>Links</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li><Link onClick={() => handleClickScroll('home')} to="/">Home</Link></li>
                    <li><Link onClick={() => handleClickScroll('map')} to="/#map">Map</Link></li>
                    <li><Link onClick={() => handleClickScroll('amenities')} to="/#amenities">Amenities</Link></li>
                    <li><Link onClick={() => handleClickScroll('reviews')} to="/#reviews">Reviews</Link></li>
                    <li><Link onClick={() => handleClickScroll('contact')} to="/#contact">Contact</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Our Rooms</h2>
                </div>
                <div className="footer-link">
                  <ul>  
                    <li><Link to="/room-details/double-room">Double Room</Link></li>
                    <li><Link to="/room-details/queen-room">Queen Room</Link></li>
                  </ul>
                  </div>
                </div>
            </div>           
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">                         
               Copyright &copy; Pesto Hostel {new Date().getFullYear()}. All rights reserved.
            </div>
            <div className="col-lg-6 col-md-6 text-right text-xl-right">                       
{/*                <div className="footer-social">                                    
                <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                <Link to="#"><i className="fab fa-twitter"></i></Link>
                <Link to="#"><i className="fab fa-instagram"></i></Link>
              </div>  */}       
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
  );
};

export default Main
