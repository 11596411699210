import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Main = () => {
  const [mobile, setmobile] = useState(false);
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);

  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  const handleClickScroll = useCallback((id) => {
    if (id === 'top') {
      window.scrollTo(0, 0)
    }
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 col-md-10 d-none ">
              { /* TODO: Put back the "d-lg-block" property if address and phone is needed */ }
                <div className="header-cta">
                  <ul>
                    <li>
                      <i className="far fa-house"></i>
                      <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/6eR1VQK6ZoRXorPS8">
                      1053 Budapest, Veres Pálné utca 16.
                      </a>
                    </li>
                    <li>
                      <i className="far fa-mobile"></i>
                      <strong><a href="tel: +36 70 946-9240">+36 70 946-9240</a></strong>
                    </li>
                  </ul>
                </div>
              </div>
{/*               <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
                <div className="header-social">
                  <span>
                    <Link to="#" title="Facebook">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="#" title="LinkedIn">
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo">
                    <Link to="/">
                      <img src="/assets/pesto-logo_200.svg" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8">
                  <div className="main-menu text-center">
                    <nav>
                      <ul>
                        <li>
                          <Link onClick={() => handleClickScroll('top')} to="/">Home</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleClickScroll('rooms')} to="/#rooms">Rooms</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleClickScroll('map')} to="/#map">Map</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleClickScroll('amenities')} to="/#amenities">Amenities</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleClickScroll('reviews')} to="/#reviews">Reviews</Link>
                        </li>
                        {/* <li>
                          <Link to="/#review">Review</Link>
                        </li> */}
                        <li>
                          <Link onClick={() => handleClickScroll('contact')} to="/#contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#nav"
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        onClick={() =>
                          setmobile(mobile === true ? false : true)
                        }
                        style={{
                          right: "0px",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <>
                            <span>
                              <span>
                                <span></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>
                      <nav className="mean-nav">
                        {mobile && (
                          <ul style={{ display: mobile ? "block" : "none" }}>
                            <li>
                              <Link onClick={() => handleClickScroll('top')} to="/">Home</Link>
                            </li>
                            <li>
                              <Link onClick={() => handleClickScroll('rooms')} to="/#rooms">Rooms</Link>
                            </li>
                            <li>
                              <Link onClick={() => handleClickScroll('map')} to="/#map">Map</Link>
                            </li>
                            <li>
                              <Link onClick={() => handleClickScroll('amenities')} to="/#amenities">Amenities</Link>
                            </li>
                            <li>
                              <Link onClick={() => handleClickScroll('reviews')} to="/#reviews">Reviews</Link>
                            </li>
                            <li>
                              <Link onClick={() => handleClickScroll('contact')} to="/#contact">Contact</Link>
                            </li>
                            
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
