import React , { useState } from 'react'
import { Link } from 'react-router-dom'

import Slider from "react-slick"

import service1 from "../../assets/img/gallery/207866773.jpg"
import service2 from "../../assets/img/gallery/207866794.jpg"
import service3 from "../../assets/img/gallery/207931339.jpg"
import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import RoomSectionCard from '../RoomSectionCard'

const RoomsSection = (props) => {
  const service = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}


  return (
    <section {...props} className="services-area pt-113 pb-150" style={{ background: '#D0D0AF66' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div className="section-title center-align mb-50 text-center">
              <h5>The pleasure of luxury</h5>
              <h2>Rooms</h2>
              <p>In Pesto Hostel there are two double bed rooms and a 3 bed private room. The big room is extendible up till 4 with spare beds. There is also a shared kitchen and dining room for self-catering guests. The temperature and climate of the flat are excellent due to the old building's huge walls and high ceiling.</p>
            </div>
          </div>
        </div>
        <Slider className="row services-active" {...service}>
          {RoomSectionCard({
            cardImg: "/double-room/207866786.jpg",
            slug: 'double-room',
            name: "Double Room",
            desc: "This bright, recently refurbished room is facing a quiet walking street and comes with shared bathroom facilities.",
            children: [
              <li className="fal fa-bed" alt="img"></li>,
              <li className="fal fa-wifi" alt="img"></li>,
              <li className="fal fa-bath" alt="img"></li>, 
              <li className="fal fa-dryer-alt" alt="img"></li>, 
              <li className="fal fa-parking-circle" alt="img"></li>
            ]
          })}

          {RoomSectionCard({
            cardImg: '/triple-room/207866804.jpg',
            // cardImg: "/queen-room/207931339.jpg",
            slug: "queen-room",
            name: "Queen Room",
            desc: "This room is connected to shared bathroom facilities and a fully-equipped common kitchen.",
            children: [
              <li className="fal fa-bed" alt="img"></li>,
              <li className="fal fa-wifi" alt="img"></li>,
              // <li className="fal fa-mug-hot" alt="img"></li>,
              <li className="fal fa-bath" alt="img"></li>,
              <li className="fal fa-dryer-alt" alt="img"></li>,
              <li className="fal fa-parking-circle" alt="img"></li>
            ]
          })}
        </Slider>
      </div>
    </section>
  )
}

export default RoomsSection
