import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import roomData from '../../../roomData.json'
import Navbar from '../../../component/Navbar/Main'
import Footer from '../../../component/Footer/Main'



const Main = () => {
  const { roomId } = useParams();

  const {
    title,
    description,
    heroImg,
    img1,
    img2,
    img3,
    img4,
    gallery,
    featureIcons,
  } = roomData[roomId]

  const [{ checkin, checkout }, setState] = useState({
    checkin: null,
    checkout: null
  })

  const getBookHref = () => {
    const [coy, com, cod] = checkout?.split('-').map(v => +v) || []
    const [ciy, cim, cid] = checkin?.split('-').map(v => +v) || []
    const bookUrl = `https://bookitbutton.booking.com/hotel/hu/pesto-hostel.en.html?aid=330843&hotel_id=314345&lang=en&pb=&stage=0&hostname=bookitbutton.booking.com&checkin_monthday=${cid}&checkin_year_month=${ciy}-${cim}&checkout_monthday=${cod}&checkout_year_month=${coy}-${com}`
    return bookUrl
  }

  // 

  // https://bookitbutton.booking.com/hotel/hu/pesto-hostel.en.html?checkout_monthday=${cod}&lang=en&checkout_year_month=${coy}-${com}&pb=&hostname=bookitbutton.booking.com&checkin_monthday=${cid}&checkin_year_month=${ciy}-${cim}&auth_success=1&hotel_id=314345&stage=0

  const getChangeHandler = direction => ({ target }) => {
    setState(prevState => ({
      ...prevState,
      [direction]: target.value
    }))
  }

  const [toggler, setToggler] = useState(false);
  const [activeImage , setActiveImage] = useState(1)
  

  return (
    <>
    <Navbar />
      <main>
        <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${heroImg})`}}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>{title}</h2>    
                    <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Services Details</li>
                      </ol>
                    </nav>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="about-area5 about-p p-relative">
          <div className="container pt-120 pb-40">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 order-2">
                <aside className="sidebar services-sidebar">
                  <div className="sidebar-widget categories">
                    <div className="widget-content">
                      <h2 className="widget-title">  Book A Room  </h2>
                      <div className="booking">
                        <div className="contact-bg"> 
                          
                            <div className="row">
                            <div className="col-lg-12">
                              <div className="contact-field p-relative c-name mb-20">                                    
                                <label htmlFor="checkin"><i className="fal fa-badge-check"></i> Check In Date</label>
                                <input onChange={getChangeHandler('checkin')} value={checkin} type="date" id="checkin" name="date"/>
                              </div>                               
                            </div>
                            <div className="col-lg-12">                               
                              <div className="contact-field p-relative c-subject mb-20">                                   
                              <label htmlFor="checkout"><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                <input onChange={getChangeHandler('checkout')} value={checkout} type="date" id="checkout" name="date"/>
                              </div>
                            </div>		
{/*                             <div className="col-lg-12">                               
                              <div className="contact-field p-relative c-subject mb-20">                                   
                                <label><i className="fal fa-users"></i> Adults</label>
                                <select name="adults" id="adu">
                                <option value="sports-massage">Adults</option>
                                <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </div>	
                            <div className="col-lg-12">                               
                              <div className="contact-field p-relative c-option mb-20">                                   
                                <label><i className="fal fa-concierge-bell"></i> Room</label>
                                <select name="room" id="rm">
                                <option value="sports-massage">Room</option>
                                <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="col-lg-12">
                              <div className="slider-btn mt-15">                                          
                                <a target="_blank" rel="noreferrer" href={getBookHref()}>
                                  <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">
                                    <span>Check availability</span>
                                  </button>				
                                </a>
                              </div>                             
                            </div>
                          </div>
                        
                        </div>  
                      </div>
                    </div>
                  </div>
                  <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                    <h3 className="h3-title">If You Need Any Help Contact With Us</h3>
                    <a href="mailto:pestohostel@gmail.com">pestohostel@gmail.com</a>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 order-1">
                <div className="service-detail">
                <Link to="#" className="gallery-link popup-image" onClick={() => {
                  setToggler(!toggler)
                  setActiveImage(2)
                  }}>
                  <div className="two-column">
                    <div className="row">
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image"><img src={img1} alt="room"/></figure>
                        <figure className="image"><img src={img2} alt="room"/></figure>
                      </div>
                      <div className="image-column col-xl-6 col-lg-12 col-md-12">
                        <figure className="image"><img src={img3} alt="room"/></figure>
                        <figure className="image"><img src={img4} alt="room"/></figure>
                      </div>                                   
                    </div>
                  </div>
                  </Link>
                  <div className="content-box">
                    <div className="row align-items-center mb-50">
                      <div className="col-lg-6 col-md-6">
                        <div className="price">
                          <h2>Room Details</h2>
                          {/* <span>$400/Night</span> */}
                        </div>
                      </div>
                      <div className="col-lg-6 text-right">
                        {/* <img src="/assets/img/testimonial/review-icon.png" alt="img"/> */}
                      </div>
                    </div>
                    <p>{description}</p>
                    <h3>Room Features</h3>
                    <ul className="room-features d-flex align-items-center">
                      {featureIcons.map((room) => {
                        return (
                          <li>
                            <i className={room.icon}></i> 
                            <label>{room.label}</label>
                          </li>
                        );
                      })}
                    </ul>
{/*                     <h3>Children and extra beds.</h3>
                    <p>Children are welcome Kids stay free! Children stay free when using existing bedding; children may not be eligible for complimentary breakfast Rollaway/extra beds are available for $ 10 per day.Interdum et malesu they adamale fames ac anteipsu pimsine faucibus curabitur arcu site feugiat in tortor in, volutpat sollicitudin libero. Hotel non lorem acer suscipit bibendum vulla facilisi nedeuter nunc volutpa mollis sapien velet conseyer turpeutionyer masin libero sempe mollis.</p> */}
{/*                     <div className="mb-50">
                      <Link to="/contact" className="btn ss-btn">Book This Room</Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FsLightbox
          toggler={toggler}
          sources={gallery}
          slide={activeImage}
        />        
      </main>
    <Footer />
    </>
  )
}

export default Main