const DirectionsSection = ({ ...rest }) => {
  return (
    <section {...rest} className="about-area about-p pt-120 pb-120 p-relative fix">
    <div className="container">
      <div className="direction-container row justify-content-center align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.9316392552814!2d19.05395451562654!3d47.49124507917722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc444fc02acf%3A0xa921ff5e6c46341a!2sPesto%20Hostel!5e0!3m2!1shu!2shu!4v1689152880262!5m2!1shu!2shu" width="100%" height="450" style={{border:0}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 pt-40 pb-40">
          <div
            className="about-content s-about-content  wow fadeInRight  animated pl-30"
            data-animation="fadeInRight"
            data-delay=".4s"
          >
            <div className="about-title second-title pb-25">
              <h5>How to Get There</h5>
              <h2>Directions</h2>
            </div>
            <p>
            Pesto Hostel located in the middle of the downtown on a quiet street, 2 minutes walk from the river Danube. The freshly renovated flat in a nice 19th century building is the good spot to start sightseeings and to recreate.
            </p>
            <div className="mt-30">
              <div className="row justify-content-center align-items-center">
                <h5 className="col-md-12 green mb-30 font-black text-lg">
            1053 Budapest, Veres Pálné utca 16.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default DirectionsSection
