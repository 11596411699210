import React from 'react';
import { Link } from "react-router-dom";


const ErrorPage = () =>{
    return (
      <div className="error-page">
        <div className="logo">
          <Link to="/">
            <img src="/assets/pesto-logo_200.svg" alt="logo" />
          </Link>
        </div>
        <h2>Something went wrong!</h2>
        <hr />
        <p>
          <Link to="/">Go back to the home page</Link>
        </p>
      </div>
    );
  }

export default ErrorPage;